import { getShortText, getSingleImage } from "./Publications"
import { imageUrl } from "./ImageUrl"

export function formatPageInformation(data, type) {
  if (!data) return
  return {
    page: data.title ? data.title.body : "",
    shareImage: data.image
      ? imageUrl(getSingleImage(data.image), 1200, 630)
      : "",
    shareDescription: data.shorttext ? getShortText(data.shorttext) : "",
    type: type ? type : "website",
  }
}
