import React, { useState, useEffect } from "react"
import { useGuestApi } from "../../hooks/useApi"
import { Link } from "gatsby"
import {
  getColor,
  getSingleImage,
  getPerson,
  getTitle,
  getTimeSlot,
} from "../../helpers/Publications"
import ProgramLoader from "../Loader/ProgramLoader"
import GridEmpty from "./GridEmpty"
import { useTranslation } from "react-i18next"
import { imageUrl } from "../../helpers/ImageUrl"
import { getImageType } from "../../helpers/Prepr"

const initialPagination = {
  total: null,
  skip: 0,
  limit: 30,
}

export default function ProgramsGrid({ history }) {
  const [pagination, setPagination] = useState(initialPagination)
  const [page, setPage] = useState(1)
  const { getProgramsPublications } = useGuestApi()
  const [programs, setPrograms] = useState([])
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()
  useEffect(fetchProgramsPublications, [page])

  function fetchProgramsPublications() {
    getProgramsPublications(pagination).then(response => {
      setPagination({
        total: response.total,
        limit: response.limit,
        skip: response.skip,
      })
      setPrograms(programs.concat(response.grid.items))
      setLoading(false)
    })
  }

  return (
    <div className="programs__grid">
      {!programs.length && loading && (
        <>
          <ProgramLoader />
          <ProgramLoader />
          <ProgramLoader />
          <ProgramLoader />
          <ProgramLoader />
          <ProgramLoader />
        </>
      )}
      {!programs.length && !loading && (
        <GridEmpty>{t("gridEmpty.programs")}</GridEmpty>
      )}
      {programs.map((program, i) => (
        <div className={`program`} key={i}>
          <Link to={`/programmas/${program.slug}`} className="program__inner">
            <div className="program__visual">
              <img
                src={imageUrl(
                  getSingleImage(
                    getImageType(
                      "image_website_block",
                      program.image,
                      null,
                      program.image_website_block,
                      null
                    )
                  ),
                  425,
                  220
                )}
                alt={getTitle(program.title)}
              />
            </div>
            <div
              className="program__content"
              style={{ backgroundColor: getColor(program.color) }}
            >
              {program.now_playing && (
                <div className="program__playing">
                  <p>Nu op Play Nostalgie</p>
                  <span className="program__playlist">
                    <i className="icon-playlist" />
                    <span>Playlist</span>
                  </span>
                </div>
              )}
              <div className="program__name">
                <h2>{getTitle(program.title)}</h2>
                <h3>{getPerson(program.person)}</h3>
              </div>
              <div className="program__info">
                <p>{getTimeSlot(program.timeslot)}</p>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}
