import React from "react"
import Skeleton from "react-loading-skeleton"

export default function ProgramLoader({ nowPlaying }) {
  return (
    <div className={`program${nowPlaying ? " now-playing" : ""}`}>
      <div className="program__inner">
        <div className="program__visual">
          <Skeleton width={"100%"} height={"100%"} circle={false} />
        </div>
        <div className="program__content">
          <div className="program__name">
            <Skeleton width={"100%"} height={45} circle={false} />
          </div>
          <div className="program__info">
            <Skeleton width={"100%"} height={20} circle={false} />
          </div>
        </div>
      </div>
    </div>
  )
}
