import React from "react"
import ProgramsCarousel from "../../components/Carousel/ProgramsCarousel"
import ProgramsGrid from "src/components/Grid/ProgramsGrid"
import Container from "../../components/Layout/Container"
import "./Overview.scss"
import ProgramNav from "./Nav"

function ProgramsOverview() {
  return (
    <Container page="Programma's">
      <div className="programs container">
        <ProgramNav />
        <ProgramsGrid />
      </div>
    </Container>
  )
}

export default ProgramsOverview
