import React from "react"
import { Link } from "gatsby"
import "./Nav.scss"

export default function ProgramNav({ active }) {
  return (
    <ul className="program__nav">
      <li className="program__nav--item">
        <Link
          to="/programmas"
          className="program__nav--link"
          activeClassName="active"
        >
          Programma's
        </Link>
      </li>
      <li className="program__nav--item">
        <Link
          to="/programmagids"
          className="program__nav--link"
          activeClassName="active"
        >
          Deze week bij Play Nostalgie
        </Link>
      </li>
    </ul>
  )
}
