import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import MainContent, {
  MainContentLoading,
} from "../../components/Layout/MainContent"
import Title from "../../components/Text/Title"
import { returnLayoutSection } from "../../helpers/Layout"
import { formatPageInformation } from "../../helpers/Meta"
import { getImageType } from "../../helpers/Prepr"
import {
  convertToSlug,
  getPerson,
  getSingleImage,
  getTagsIds,
  getTimeSlot,
  getTitle,
} from "../../helpers/Publications"
import withGuestLayout from "../../hoc/withGuestLayout"
import { useGuestApi } from "../../hooks/useApi"
import Card from "../Card/Card"
import Error404 from "../Errors/404"
import Icon from "../Icon/Icon"
import "./Detail.scss"

function ProgramDetail({ slug }) {
  const { getPublicationBySlug, setPageInformation, getRelatedNewsByTags } =
    useGuestApi()
  const [programItem, setProgramItem] = useState(null)
  const [relatedNews, setRelatedNews] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(
    function fetchPublications() {
      getPublicationBySlug(slug).then(response => {
        setLoading(false)
        setPageInformation(formatPageInformation(response))
        setProgramItem(response)
        if (window.opix && response) {
          opix("event", "View", { publication: response.id })
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slug]
  )

  useEffect(() => {
    if (programItem) {
      const tags = getTagsIds(programItem.tag)
      if (tags.length > 0) {
        getRelatedNewsByTags(tags).then(response => {
          setRelatedNews(response)
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programItem])

  return (
    <>
      {loading && <MainContentLoading />}
      {programItem && (
        <MainContent {...programItem} imageType={"image_website_detail"}>
          <div className="program__detail">
            <Title>{getTitle(programItem.title)}</Title>
            {programItem.person && (
              <h2 className="program__person">
                {getPerson(programItem.person)}
              </h2>
            )}
            <div className="program__time">
              <Icon name="clock" />
              <p>{getTimeSlot(programItem.timeslot)}</p>
            </div>
            {programItem.layout
              ? Array.isArray(programItem.layout.items)
                ? programItem.layout.items.map((item, i) => {
                    return returnLayoutSection(item, i)
                  })
                : null
              : null}
          </div>

          {relatedNews.length > 0 && (
            <div className="program__related">
              <h2 className="program__related--title">
                Meer over dit programma
              </h2>
              <div className="program__related--grid">
                {relatedNews.map((item, index) => {
                  return (
                    <Card
                      key={index}
                      title={getTitle(item.title)}
                      image={getSingleImage(
                        getImageType(
                          "image_website_block",
                          item.image,
                          null,
                          item.image_website_block,
                          null
                        )
                      )}
                      route={convertToSlug(item.model_id, item.slug)}
                      onClickCard={() =>
                        navigate(convertToSlug(item.model_id, item.slug))
                      }
                    />
                  )
                })}
              </div>
            </div>
          )}
        </MainContent>
      )}
      {!programItem && !loading && <Error404 />}
    </>
  )
}

export default withGuestLayout(ProgramDetail, {
  page: "Programma",
})
