import React from "react"
import { Router } from "@reach/router"
import ProgramsOverview from "../components/Programs/Overview"
import ProgramDetail from "../components/Programs/Detail"

const Programs = () => {
  return (
    <Router>
      <ProgramsOverview path="programmas" />
      <ProgramDetail path="programmas/:slug" />
    </Router>
  )
}

export default Programs
